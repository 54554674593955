import { ReactComponent as LogoIcon } from '../../Assets/bookleLogo.svg';
import { CONSULT_URL } from 'Constants';
import s from './logo.module.scss';

const Logo = () => {
  const redirectToHome = () => {
    window.location.href = CONSULT_URL;
  };
  return (
    <div className={s.container}>
      <LogoIcon onClick={redirectToHome} className={s.cursor} />;
    </div>
  );
};

export default Logo;
